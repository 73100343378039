import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import style from './PeopleList.module.scss';
import Slider from 'react-slick';
import { useWindowSize } from '@react-hook/window-size/throttled';
import { SmartLink } from '../SmartLink';

const PeopleList = props => {
    const { title, cta, items } = props;
    if (items.length <= 0) {
        return <></>;
    }

    const sliderEl = useRef(null);
    const wrapRef = useRef(null);
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    const arrowsRef = useRef(null);

    const sliderParams = {
        slidesToShow: 1,
        // arrows: false,
        prevArrow: (
            <button className={style.prevBtn} ref={prevRef}>
                <span className='icon-arrow-right' />
            </button>
        ),
        nextArrow: (
            <button className={style.nextBtn} ref={nextRef}>
                <span className='icon-arrow-right' />
            </button>
        ),
        infinite: false,
        speed: 700,
        draggable: true,
        swipe: true,
        variableWidth: false,
        responsive: [
            {
                breakpoint: 9999,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1360,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 599,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 460,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
        ],
        afterChange: slide => {
            // checkArrow(slide);
        },
        onInit: () => {
            // const fragment = document.createDocumentFragment();
            // fragment.appendChild(prevRef.current);
            // fragment.appendChild(nextRef.current);
            // if (arrowsRef.current.children.length > 0) {
            //     arrowsRef.current.innerHTML = '';
            // }
            // arrowsRef.current.appendChild(fragment);
        },
    };

    const wSize = useWindowSize();

    useEffect(() => {
        const arrH = prevRef.current?.offsetHeight;
        arrowsRef.current.style.height = `${arrH}px`;
        const arrowsBound = arrowsRef.current.getBoundingClientRect();
        const wrapBound = wrapRef.current.firstElementChild.getBoundingClientRect();
        const diffX = arrowsBound.right - wrapBound.right;
        const diffY = arrowsBound.top - wrapBound.top;
        if (prevRef.current && nextRef.current) {
            prevRef.current.style.top = `${diffY}px`;
            prevRef.current.style.right = `${-diffX + 40}px`;
            nextRef.current.style.top = `${diffY}px`;
            nextRef.current.style.right = `${diffX * -1}px`;
        }
    }, [wSize]);

    return (
        <div
            className={[
                style.el,
                'people-list',
                title === 'Related Team' ? style.diffLayout : '',
                cta?.link?.url ? '' : style.noBorder,
            ].join(' ')}
        >
            <div className={[style.container, 'container'].join(' ')}>
                <div className={style.slickArrows} ref={arrowsRef} />
                <div className={style.title}>
                    <h2>{title}</h2>
                </div>
                <div className={style.wrapper} ref={wrapRef}>
                    <Slider {...sliderParams} ref={sliderEl}>
                        {items.map(({ image, name, link }, id) => (
                            <div key={`item${id}`}>
                                <SmartLink href={link.url} className={style.peopleImg}>
                                    <div className={style.imageWrap}>
                                        <div
                                            style={{
                                                backgroundImage: `url(${
                                                    image && image.url ? image.url : '/images/default.png'
                                                }`,
                                            }}
                                            className={style.image}
                                        />
                                    </div>
                                </SmartLink>
                                <div className={style.peopleName}>{name}</div>
                            </div>
                        ))}
                    </Slider>
                </div>
                {cta?.link?.url && (
                    <div className={style.cta}>
                        <div className={style.ctaLink}>
                            <SmartLink href={cta.link.url} hasArrow className='arrow-link'>
                                {cta.text}
                            </SmartLink>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

PeopleList.propTypes = {
    cta: PropTypes.object,
    title: PropTypes.string,
    items: PropTypes.array,
    type: PropTypes.string,
};
export default PeopleList;
PeopleList.defaultProps = {};
